<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    item-text="text"
    item-value="email"
    small-chips
    clearable
    outlined
    :loading="isLoading"
    @change="emitChosenUser"
  />
</template>

<script>
import { gql } from "apollo-boost";
import _ from "lodash";

export default {
  name: "ConsWithEmailPicker",
  props: {
    initial: {
      type: Object,
      default: () => ({
        name: "",
        email: "",
      }),
    },
  },
  data() {
    return {
      chosenUser: null,
      totalRecords: 0,
      isLoading: true,
      returnedUsers: [],
      formattedUsers: [],
    };
  },
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
              email
            }
          }
        }
      `,
      variables() {
        return {
          limit: 100,
          query: { role: "consultant" },
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.returnedUsers = data.users.records;
        this.formattedUsers = _.map(this.returnedUsers, (user) => ({
          text: `${user.firstName} ${user.lastName}`,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        }));

        this.setInitialUser();
      },
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler() {
        this.setInitialUser();
      },
    },
    formattedUsers: {
      handler() {
        this.setInitialUser();
      },
    },
  },
  methods: {
    setInitialUser() {
      // Check if an email or a name has been provided in the initial prop
      if (
        (this.initial.email || this.initial.name) &&
        this.formattedUsers.length > 0
      ) {
        let existingUser;
        // First try to match by email if available
        if (this.initial.email) {
          existingUser = this.formattedUsers.find(
            (user) => user.email === this.initial.email
          );
        }
        // If email didn't match or wasn't provided, try matching by name
        if (!existingUser && this.initial.name) {
          existingUser = this.formattedUsers.find(
            (user) => user.name === this.initial.name
          );
        }
        if (existingUser) {
          this.chosenUser = existingUser.email;
          console.log("Set Initial User:", existingUser);
        } else {
          console.warn(
            "Initial User Not Found:",
            this.initial.email || this.initial.name
          );
        }
      }
    },
    emitChosenUser() {
      const selectedUser = this.formattedUsers.find(
        (user) => user.email === this.chosenUser
      );
      if (selectedUser) {
        this.$emit("consultant-selected", {
          name: selectedUser.name,
          email: selectedUser.email,
        });
      }
    },
  },
  async mounted() {
    console.log("Component Mounted, Initial Value:", this.initial);
    await this.$apollo.queries.users.refetch();
  },
};
</script>
