<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="[
            'All consultants',
            ...consultants.map((consultant) => consultant.consultantName),
          ]"
          label="Consultant Picker"
          multiple
          v-model="selectedConsultant"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="3">
        <referral-picker
          :label="'Source Picker'"
          :initial="sourceSelection"
          :chosen-referral.sync="sourceSelection"
          outlined
        />
      </v-col>
    </v-row>
    <div class="text-right">
      <v-btn class="mb-2" color="primary" @click="downloadData">Download</v-btn>
      <v-btn class="mb-2" @click="openFilterDialog">Filter by Date</v-btn>
      <v-btn class="mb-2" color="black" @click="clearDateFilter"
        >Clear Date Filter</v-btn
      >
    </div>

    <v-dialog v-model="filterDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-form>
            <v-date-picker
              v-model="dateRange"
              range
              label="Date Range"
            ></v-date-picker>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="fetchConsultantsProgressReportData">Apply</v-btn>
          <v-btn @click="filterDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <h1>{{ this.dateRange ? this.dateRange.join(" to ") : "" }}</h1>
    </div>
    <div class="table-container">
      <table class="styled-table">
        <thead>
          <tr>
            <th>Consultant Name</th>
            <th class="'light-blue'">Total</th>
            <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="consultant in filteredConsultants"
            :key="consultant.tempId"
          >
            <td>{{ consultant.consultantName }}</td>
            <td class="light-blue">{{ getTotal(consultant) }}</td>
            <td v-for="header in tableHeaders" :key="header">
              {{ getProgressCount(consultant, header) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { GET_CONSULTANTS_REPORT_DATA } from "../../../../queries";
import ReferralPicker from "../Pickers/ReferralPicker.vue";

export default {
  components: { ReferralPicker },
  name: "ConsultantReport",
  data() {
    return {
      filterDialog: false,
      dateRange: [],
      consultants: [],
      progressFilter: {},
      selectedConsultant: [],
      sourceSelection: "Medshield",
    };
  },
  computed: {
    tableHeaders() {
      const uniqueProgressValues = Array.from(
        new Set(
          this.consultants.flatMap((consultant) =>
            consultant.progressCounts.map((progress) => progress.progress)
          )
        )
      );

      return uniqueProgressValues;
    },
    filteredConsultants() {
      if (this.selectedConsultant.includes("All consultants")) {
        return this.consultants;
      } else if (this.selectedConsultant.length > 0) {
        return this.consultants.filter((consultant) =>
          this.selectedConsultant.includes(consultant.consultantName)
        );
      } else {
        return this.consultants;
      }
    },
  },
  methods: {
    getTotal(consultant) {
      let total = 0;
      this.tableHeaders.slice(1).forEach((header) => {
        // Exclude 'Consultant Name' from summing
        total += this.getProgressCount(consultant, header);
      });
      return total;
    },
    openFilterDialog() {
      this.filterDialog = true;
    },
    async fetchConsultantsProgressReportData() {
      this.isLoading = true;

      let startDate = null;
      let endDate = null;

      if (this.dateRange && this.dateRange.length === 2) {
        startDate = this.dateRange[0];
        endDate = this.dateRange[1];
      } else {
        const today = new Date();
        const currentYear = today.getFullYear();
        startDate = `${currentYear}-01-01`;
        endDate = today.toISOString().slice(0, 10);
      }

      try {
        const response = await this.$apollo.query({
          query: GET_CONSULTANTS_REPORT_DATA,
          variables: {
            startDate,
            endDate,
            source: this.sourceSelection,
          },
        });

        const consultantReportData = response.data.consultantsProgressReport;
        // Add a unique ID if it doesn't exist
        this.consultants = consultantReportData.map((consultant, index) => ({
          ...consultant,
          tempId: `consultant-${index}`, // Temporary unique ID
        }));
        this.isLoading = false;
        this.filterDialog = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },
    getProgressCount(consultant, progress) {
      const progressEntry = consultant.progressCounts.find(
        (entry) => entry.progress === progress
      );
      return progressEntry ? progressEntry.count : 0;
    },
    downloadData() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Consultants Data");
      const data = [];
      const headers = [
        "Consultant Name",
        ...this.tableHeaders.map((header) => header),
        "Total",
      ];
      data.push(headers);
      this.filteredConsultants.forEach((consultant) => {
        const row = [consultant.consultantName];
        let total = 0;
        this.tableHeaders.forEach((header) => {
          const count = this.getProgressCount(consultant, header);
          total += count;
          row.push(count);
        });
        row.push(total); // Append total to the row
        data.push(row);
      });

      worksheet.addRows(data);

      // Generate a blob for the workbook
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, "consultants_data.xlsx");
      });
    },
    clearDateFilter() {
      const today = new Date();
      const currentYear = today.getFullYear();
      this.dateRange = [
        `${currentYear}-01-01`,
        today.toISOString().slice(0, 10),
      ];
      this.fetchConsultantsProgressReportData();
    },
  },
  watch: {
    selectedConsultant: function (newConsultant) {
      console.log("selectedConsultant changed to:", newConsultant);
    },
    sourceSelection: function (newSource) {
      this.fetchConsultantsProgressReportData();
    },
  },

  created() {
    this.fetchConsultantsProgressReportData();
  },
};
</script>
<style>
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0; /* Add a border to the table */
}

.styled-table th,
.styled-table td {
  border: 1px solid #e0e0e0; /* Add borders to table cells */
  padding: 8px;
  text-align: center;
}

.styled-table th {
  background-color: #f5f5f5; /* Light background color for table headers */
}
.light-blue {
  background-color: #e0e0f0; /* Light blue background color */
}
</style>
