<template>
  <v-autocomplete
    v-model="chosenProduct"
    v-bind="$attrs"
    :items="formattedProducts"
    item-text="text"
    item-value="insurer"
    small-chips
    clearable
    outlined
    :loading="isLoading"
    @change="emitChosenProduct"
  />
</template>

<script>
import { gql } from "apollo-boost";
import _ from "lodash";

export default {
  name: "ProductProviderPicker",
  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    chosenProduct: null,
    totalRecords: 0,
    isLoading: true,
    returnedProducts: [],
    formattedProducts: [],
  }),
  apollo: {
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              insurerName
              category
              description
            }
          }
        }
      `,
      variables() {
        return {
          limit: 600,
          skip: 0,
          query: {},
        };
      },
      fetchPolicy: "network-only",
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result({ data }) {
        this.returnedProducts = data.products.records;
        this.formattedProducts = _.map(this.returnedProducts, (product) => ({
          text: `${product.insurerName} - ${product.category} - ${product.name}`,
          insurer: product.insurerName,
          category: product.category,
        }));
      },
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler() {
        this.setInitialProduct();
      },
    },
    formattedProducts: {
      immediate: true,
      handler() {
        this.setInitialProduct();
      },
    },
  },
  methods: {
    setInitialProduct() {
      if (this.initial.insurer && this.formattedProducts.length > 0) {
        const existingProduct = this.formattedProducts.find(
          (product) => product.insurer === this.initial.insurer
        );
        if (existingProduct) {
          this.chosenProduct = existingProduct.insurer;
        }
      }
    },
    emitChosenProduct() {
      const selectedProduct = this.formattedProducts.find(
        (product) => product.insurer === this.chosenProduct
      );
      if (selectedProduct) {
        this.$emit("chosen-product", {
          insurer: selectedProduct.insurer,
          category: selectedProduct.category,
        });
      }
    },
  },
  async mounted() {
    await this.$apollo.queries.products.refetch();
  },
};
</script>
