<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="mb-4">
          <v-btn color="primary" @click="uploadDialog = true"
            >Upload Agent comission</v-btn
          >
          <v-btn color="success" class="ml-4" @click="openCreateDialog"
            >Create agent commision</v-btn
          >
          <v-btn color="secondary" class="ml-4" @click="reportDialog = true"
            >Run report</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Agent or Product"
            outlined
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="agentCommissions"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mx-2" @click="openEditDialog(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                @click="deleteAgentCommission(item.id)"
                class="btn btn-danger"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Upload Dialog -->
    <v-dialog v-model="uploadDialog" max-width="600">
      <v-card>
        <v-card-title>Upload Agent Comission</v-card-title>
        <v-card-text>
          <v-select
            v-model="newCommission.month"
            outlined
            :items="selectOptions"
            label="Select Month"
          ></v-select>
          <v-file-input
            v-model="file"
            label="Drag and drop an excel file or click to upload"
            accept=".xlsx, .xls"
            show-size
            truncate-length="15"
            outlined
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="uploadDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="uploadFile">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <v-card>
        <v-card-title>Edit Agent Commission</v-card-title>
        <v-card-text>
          <v-select
            v-model="editedCommission.month"
            outlined
            :items="selectOptions"
            label="Select Month"
          ></v-select>
          <cons-with-email-picker
            :initial="{
              name: editedCommission.agentReferrer,
              email: editedCommission.agentEmail,
            }"
            @consultant-selected="handleConsultantSelected"
          />

          <product-provider-picker
            :initial="{
              insurer: editedCommission.productProvider,
              category: editedCommission.productType,
            }"
            @chosen-product="handleProductSelected"
          />

          <v-text-field
            v-model="editedCommission.agentComm"
            label="Agent Comm"
            type="number"
            outlined
            suffix="%"
          ></v-text-field>
          <v-text-field
            v-model="editedCommission.referralComm"
            label="Referral Comm"
            type="number"
            outlined
            suffix="%"
          ></v-text-field>
          <v-text-field
            v-model="editedCommission.maxComm"
            label="Max Comm"
            type="number"
            outlined
            prefix="R"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="updateCommission">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create Dialog -->
    <v-dialog v-model="createDialog" max-width="600">
      <v-card>
        <v-card-title>Create Agent Commission</v-card-title>
        <v-card-text>
          <v-select
            v-model="newCommission.month"
            outlined
            :items="selectOptions"
            label="Select Month"
          ></v-select>

          <cons-with-email-picker
            @consultant-selected="handleConsultantSelected"
          />
          <product-provider-picker @chosen-product="handleProductSelected" />

          <v-text-field
            v-model="newCommission.agentComm"
            label="Agent Comm"
            type="number"
            outlined
            suffix="%"
          ></v-text-field>
          <v-text-field
            v-model="newCommission.referralComm"
            label="Referral Comm"
            type="number"
            outlined
            suffix="%"
          ></v-text-field>
          <v-text-field
            v-model="newCommission.maxComm"
            label="Max Comm"
            type="number"
            prefix="R"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="createDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="createCommission">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Run report -->
    <v-dialog v-model="reportDialog" max-width="600">
      <v-card>
        <v-card-title>Run an agent commission report</v-card-title>
        <v-card-text>
          <p>Select a period to run the report</p>
          <v-select
            v-model="newCommission.month"
            outlined
            :items="selectOptions"
            label="Select Month"
          ></v-select>
          <h2>Or</h2>
          <p>Select a consultant</p>
          <cons-with-email-picker
            @consultant-selected="handleConsultantSelected"
          />
          <h2>Or</h2>
          <p>Select a product</p>
          <product-provider-picker @chosen-product="handleProductSelected" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="reportDialog = false">Cancel</v-btn>
          <v-btn color="primary">Run report</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import NewConsultantPicker from "../Pickers/NewConsultantPicker.vue";
import NewProductPicker from "../Pickers/NewProductPicker.vue";
import ConsWithEmailPicker from "../Pickers/ConsWithEmailPicker.vue";
import ProductProviderPicker from "../Pickers/ProductProviderPicker.vue";

const GET_AGENT_COMMISSIONS = gql`
  query GetAgentCommissions {
    getAgentCommissions {
      id
      month
      agentReferrer
      productType
      productProvider
      agentComm
      referralComm
      maxComm
    }
  }
`;

const UPLOAD_AGENT_COMMISSIONS = gql`
  mutation UploadAgentCommissions($data: [AgentCommissionInput!]!) {
    uploadAgentCommissions(data: $data) {
      id
      month
      agentReferrer
      agentEmail
      productType
      productProvider
      agentComm
      referralComm
      maxComm
    }
  }
`;

const DELETE_AGENT_COMMISSION = gql`
  mutation deleteAgentCommission($id: ID!) {
    deleteAgentCommission(id: $id)
  }
`;

const UPDATE_AGENT_COMMISSION = gql`
  mutation UpdateAgentCommission($commission: AgentCommissionUpdateInput!) {
    updateAgentCommission(commission: $commission) {
      id
      agentReferrer
      productType
      productProvider
      agentComm
      referralComm
      maxComm
    }
  }
`;

const CREATE_AGENT_COMMISSION = gql`
  mutation CreateAgentCommission($commission: AgentCommissionInput!) {
    createAgentCommission(commission: $commission) {
      id
      month
      agentReferrer
      productType
      productProvider
      agentComm
      referralComm
      maxComm
    }
  }
`;

export default {
  components: {
    NewProductPicker,
    NewConsultantPicker,
    ConsWithEmailPicker,
    ProductProviderPicker,
  },
  name: "AgentComms",
  data() {
    const currentDate = new Date();
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const year = currentDate.getFullYear().toString().slice(-2);
    return {
      uploadDialog: false,
      editDialog: false,
      reportDialog: false,
      createDialog: false,
      isEditing: false,
      file: null,
      selectedOption: null,
      selectOptions: [
        "Jan-25",
        "Feb-25",
        "Mar-25",
        "Apr-25",
        "May-25",
        "Jun-25",
        "Jul-25",
        "Aug-25",
        "Sep-25",
        "Oct-25",
        "Nov-25",
        "Dec-25",
      ],
      agentCommissions: [],
      search: "",
      headers: [
        { text: "Month", value: "month" },
        { text: "Agent / Referrer", value: "agentReferrer" },
        { text: "Product Type", value: "productType" },
        { text: "Product Provider", value: "productProvider" },
        { text: "Agent Comm", value: "agentComm" },
        { text: "Referral Comm", value: "referralComm" },
        { text: "Max Comm", value: "maxComm" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedCommission: {
        id: "",
        month: "",
        agentReferrer: "",
        agentEmail: "",
        productType: "",
        productProvider: "",
        agentComm: "",
        referralComm: "",
        maxComm: "",
      },
      newCommission: {
        month: `${month}-${year}`,
        agentReferrer: "",
        agentEmail: "",
        productType: "",
        productProvider: "",
        agentComm: "",
        referralComm: "",
        maxComm: "",
      },
    };
  },
  mounted() {
    this.loadAgentCommissions();
  },
  methods: {
    handleConsultantSelected({ name, email }) {
      if (this.isEditing) {
        this.editedCommission.agentReferrer = name;
        this.editedCommission.agentEmail = email;
      } else {
        this.newCommission.agentReferrer = name;
        this.newCommission.agentEmail = email;
      }
    },

    handleProductSelected({ insurer, category }) {
      if (this.isEditing) {
        this.editedCommission.productProvider = insurer;
        this.editedCommission.productType = category;
      } else {
        this.newCommission.productProvider = insurer;
        this.newCommission.productType = category;
      }
    },

    loadAgentCommissions() {
      this.$apollo
        .query({
          query: GET_AGENT_COMMISSIONS,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          this.agentCommissions = response.data.getAgentCommissions;
        })
        .catch((error) => {
          console.error("Error loading agent commissions:", error);
        });
    },

    uploadFile() {
      if (!this.file) {
        Swal.fire("Error", "Please select an Excel file.", "error");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const dataArray = new Uint8Array(e.target.result);
        const workbook = XLSX.read(dataArray, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        if (jsonData.length === 0) {
          Swal.fire("Error", "The Excel file is empty.", "error");
          return;
        }

        // Ensure the selected month is correctly assigned
        const selectedMonth = this.newCommission.month;
        if (!selectedMonth) {
          Swal.fire(
            "Error",
            "Please select a month before uploading.",
            "error"
          );
          return;
        }

        // Helper function to parse numeric values safely
        const safeParseFloat = (val) => {
          const asString = String(val).replace(/,/g, ".").trim();
          const num = parseFloat(asString);
          return isNaN(num) ? 0 : num;
        };

        // Process the data
        const mappedData = jsonData.map((row) => {
          let normalized = {};
          Object.keys(row).forEach((key) => {
            normalized[key.trim().toLowerCase()] =
              typeof row[key] === "string" ? row[key].trim() : row[key];
          });

          return {
            month: selectedMonth, // Assign selected month to each object
            agentReferrer:
              normalized["agent_referrer"] ||
              normalized["agent / referrer"] ||
              "",
            agentEmail: normalized["agent email"] || "",
            productType: normalized["product type"] || "",
            productProvider: normalized["product provider"] || "",
            agentComm: safeParseFloat(normalized["agent comm"] || "0"),
            referralComm: safeParseFloat(normalized["referral comm"] || "0"),
            maxComm: safeParseFloat(normalized["max comm"] || "0"),
          };
        });

        console.log("Mapped Data Ready for Upload:", mappedData); // Debugging

        // Send data to GraphQL mutation
        this.$apollo
          .mutate({
            mutation: UPLOAD_AGENT_COMMISSIONS,
            variables: {
              data: mappedData,
            },
          })
          .then(() => {
            Swal.fire("Success", "File uploaded successfully.", "success");
            this.uploadDialog = false;
            this.file = null;
            this.loadAgentCommissions();
          })
          .catch((error) => {
            console.error("GraphQL Upload Error:", error);
            Swal.fire("Error", "Error uploading file.", "error");
          });
      };

      reader.readAsArrayBuffer(this.file);
    },

    openEditDialog(item) {
      console.log("Opening Edit Dialog with:", item);

      this.editedCommission = { ...item };
      this.isEditing = true;
      this.editDialog = true;
    },
    findUserEmail(agentName) {
      if (!this.formattedUsers || this.formattedUsers.length === 0) {
        console.warn("formattedUsers is empty or undefined");
        return "";
      }

      const user = this.formattedUsers.find((user) => user.name === agentName);
      return user ? user.email : "";
    },
    updateCommission() {
      const commissionInput = {
        id: this.editedCommission.id,
        month: this.editedCommission.month,
        agentReferrer: this.editedCommission.agentReferrer,
        agentEmail: this.editedCommission.agentEmail,
        productType: this.editedCommission.productType,
        productProvider: this.editedCommission.productProvider,
        agentComm: Number(this.editedCommission.agentComm),
        referralComm: Number(this.editedCommission.referralComm),
        maxComm: Number(this.editedCommission.maxComm),
      };

      this.$apollo
        .mutate({
          mutation: UPDATE_AGENT_COMMISSION,
          variables: {
            commission: commissionInput,
          },
        })
        .then(() => {
          Swal.fire("Success", "Commission updated successfully.", "success");
          this.editDialog = false;
          this.loadAgentCommissions();
        })
        .catch((error) => {
          console.error("Error updating commission:", error);
          Swal.fire("Error", "Error updating commission.", "error");
        });
    },
    openCreateDialog() {
      const currentDate = new Date();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const year = currentDate.getFullYear().toString().slice(-2);

      this.newCommission = {
        month: `${month}-${year}`,
        agentReferrer: "",
        agentEmail: "",
        productType: "",
        productProvider: "",
        agentComm: "",
        referralComm: "",
        maxComm: "",
      };
      this.isEditing = false;
      this.createDialog = true;
    },
    createCommission() {
      const commissionInput = {
        month: this.newCommission.month,
        agentReferrer: this.newCommission.agentReferrer,
        agentEmail: this.newCommission.agentEmail,
        productType: this.newCommission.productType,
        productProvider: this.newCommission.productProvider,
        agentComm: Number(this.newCommission.agentComm),
        referralComm: Number(this.newCommission.referralComm),
        maxComm: Number(this.newCommission.maxComm),
      };

      console.log("Creating Commission with:", commissionInput); // Debugging

      this.$apollo
        .mutate({
          mutation: CREATE_AGENT_COMMISSION,
          variables: {
            commission: commissionInput,
          },
        })
        .then(() => {
          Swal.fire("Success", "Commission created successfully.", "success");
          this.newCommission = {
            month: this.newCommission.month, // Keep the selected month
            agentReferrer: "",
            agentEmail: "",
            productType: "",
            productProvider: "",
            agentComm: "",
            referralComm: "",
            maxComm: "",
          };
          this.createDialog = false;
          this.loadAgentCommissions();
        })
        .catch((error) => {
          console.error("Error creating commission:", error);
          Swal.fire("Error", "Error creating commission.", "error");
        });
    },

    async deleteAgentCommission(id) {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to undo this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmation.isConfirmed) {
        try {
          const response = await this.$apollo.mutate({
            mutation: DELETE_AGENT_COMMISSION,
            variables: { id },
          });

          console.log("GraphQL Response:", response); // Debugging

          if (response.data && response.data.deleteAgentCommission === true) {
            Swal.fire({
              title: "Deleted!",
              text: "The agent commission has been removed.",
              icon: "success",
            });

            this.loadAgentCommissions(); // Refresh the table after deletion
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to delete the agent commission.",
              icon: "error",
            });
          }
        } catch (error) {
          console.error("Error deleting commission:", error);
          Swal.fire({
            title: "Error!",
            text: `Something went wrong while deleting: ${error.message}`,
            icon: "error",
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
