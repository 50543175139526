<template>
  <v-btn
    color="primary"
    elevation="2"
    :loading="isLoading"
    @click="refreshLeads"
  >
    Refresh Leads from Email
  </v-btn>
</template>

<script>
import Swal from "sweetalert2";
import { gql } from "apollo-boost";

const IMPORT_EMAIL_LEADS = gql`
  mutation ImportEmailLeads {
    importEmailLeads {
      success
      message
      inserted
      updated
      read
      failed
    }
  }
`;

export default {
  name: "RefreshLeadsButton",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async refreshLeads() {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: IMPORT_EMAIL_LEADS,
        });

        const result = data.importEmailLeads;

        if (!result.success) {
          throw new Error(result.message);
        }

        const { inserted, updated, read, failed } = result;

        if (read === 0) {
          await Swal.fire({
            icon: "info",
            title: "Information",
            text: "No new emails to process.",
            timer: 2500,
            showConfirmButton: false,
          });
        } else {
          await Swal.fire({
            icon: "success",
            title: "Success",
            text: `Leads processed successfully! Read: ${read}, Inserted: ${inserted}, Updated: ${updated}, Failed: ${failed}`,
            timer: 3500,
            showConfirmButton: false,
          });
        }

        this.$router.push("/admin/leads");
      } catch (error) {
        console.error("Error refreshing leads:", error);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Failed to refresh leads: ${error.message}`,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
